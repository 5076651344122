<template>
  <div>
    <PageIntroduce
      :title="'风险感知'"
      :desc="'通过差距分析，逐项比对法规的条款要求，快速识别到产品法律风险'"
    />
    <PageTotalChart
      v-loading="!barSeriesArr.length"
      left-title="风险统计"
      :bar-series-arr="barSeriesArr"
      :backgrounds="['primary', 'danger', 'warning', 'light-warning', 'success']"
      :categories="['风险总数','严重风险数', '高风险数', '中风险数', '低风险数']"
    />
    <!--<RiskStatistics />-->
    <b-card>
      <div class="filter-wrap flex-between">
        <div class="flex-start search-filter-wrap">
          <el-input
            v-model="listQuery.SearchStr"
            width="180"
            placeholder="请输入内容"
            clearable
            @clear="handleFilter"
          />
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="handleFilter"
          >
            搜索
          </el-button>
        </div>
        <div>
          <el-button
            v-show="assginPerm"
            size="small"
            type="primary"
            icon="el-icon-thumb"
            @click="assignDialogShow"
          >
            指派
          </el-button>
          <el-button
            v-show="savePerm"
            size="small"
            type="primary"
            icon="el-icon-plus"
            @click="riskSave(1)"
          >
            新增
          </el-button>
        </div>
      </div>
      <div class="table-wrap">
        <FilterSelectList
          :list-query.sync="listQuery"
          :select-list.sync="selectList"
          @handleFilter="handleFilter"
        />
        <el-table
          v-loading="loading"
          border
          fit
          highlight-current-row
          :data="tableData"
          stripe
          style="width: 100%"
          @sort-change="sortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
          />
          <el-table-column
            prop="riskType"
            sortable
            :sort-by="'risk_type'"
            :show-overflow-tooltip="true"
            label="风险类别"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskType'"
                :select-list.sync="selectList"
                :label="'风险类别'"
                :value.sync="listQuery.riskType"
                :dropdown-options="riskTypeOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="explanation"
            label="风险描述"
          >
            <template slot-scope="{row}">
              <TableTips :text="row.explanation" />
            </template>
          </el-table-column>
          <el-table-column
            prop="riskObjectName"
            width="120"
            sortable
            :sort-by="'risk_object_name'"
            label="关联对象"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskObject'"
                :select-list.sync="selectList"
                :label="'关联对象'"
                :value.sync="listQuery.riskObject"
                :dropdown-options="riskObjectOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="riskLevel"
            sortable
            :sort-by="'risk_level'"
            width="120"
            label="风险等级"
          >
            <template slot="header">
              <TableHeaderFilterDropdown
                :key-str="'riskLevel'"
                :select-list.sync="selectList"
                :label="'风险等级'"
                :value.sync="listQuery.riskLevel"
                :dropdown-options="levelOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <b-badge
                class="d-flex justify-content-center"
                style="align-items: center"
                pill
                :variant="levelColorObj[row.riskLevel]"
              >
                {{ levelObj[row.riskLevel] }}
              </b-badge>
            </template>
          </el-table-column>
          <el-table-column
            prop="riskSourceName"
            sortable
            :show-overflow-tooltip="true"
            :sort-by="'risk_source_name'"
            width="115"
            label="风险来源"
          >
            <template
              slot="header"
              slot-scope="scope"
            >
              <TableHeaderFilterDropdown
                :key-str="'riskSource'"
                :select-list.sync="selectList"
                :label="'风险来源'"
                :value.sync="listQuery.riskSource"
                :dropdown-options="riskSourceOptions"
                @handleFilter="handleFilter"
              />
            </template>
          </el-table-column>
          <el-table-column
            width="100"
            prop="createTime"
            label="风险产生时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            prop="riskStatus"
            :sort-by="'risk_status'"
            sortable
            label="风险状态"
          >
            <template slot="header">
              <TableHeaderFilterDropdown
                :key-str="'riskStatus'"
                :select-list.sync="selectList"
                :label="'风险状态'"
                :value.sync="listQuery.riskStatus"
                :dropdown-options="riskStatusOptions"
                @handleFilter="handleFilter"
              />
            </template>
            <template slot-scope="{row}">
              <div
                :style="row.riskStatus ==2 ? {color:'#0d40b2',cursor: 'pointer'} : {}"
                @click="overruleReasonDialogShow(row)"
              >
                {{ riskStatusObj[row.riskStatus] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="disposalAdvice"
            label="处置建议"
          >
            <template slot-scope="{row}">
              <TableTips :text="row.disposalAdvice" />
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="135px"
          >
            <template slot-scope="{row}">
              <el-link
                v-if="readPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="riskDetail(row.id)"
              >
                <el-tooltip
                  content="查看"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-document" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="updatePerm"
                class="mr10"
                :underline="false"
                type="primary"
                @click="riskSave(2, row.id)"
              >
                <el-tooltip
                  content="修改"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-edit" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="delPerm"
                :underline="false"
                class="mr10"
                type="primary"
                @click="delRisk(row.id)"
              >
                <el-tooltip
                  content="删除"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-delete" />
                </el-tooltip>
              </el-link>
              <el-link
                v-if="closePerm"
                :underline="false"
                type="danger"
                @click="closeRisk(row.id)"
              >
                <el-tooltip
                  content="关闭"
                  placement="top"
                  effect="light"
                >
                  <i class="el-icon-circle-close" />
                </el-tooltip>
              </el-link>
            </template>
          </el-table-column>
        </el-table>
        <PageQuery
          v-show="total>0"
          :total="total"
          :cache-name="'listQuery10'"
          :update-state="'UPDATE_LISTQUERY10'"
          :list-query.sync="listQuery"
          :init-list-query.sync="initListQuery"
          @pagination="getList"
        />
      </div>
    </b-card>
    <RiskAssign
      ref="riskAssign"
      @handleFilter="handleFilter(1)"
    />
    <el-dialog
      :visible.sync="dialogVisible"
      width="800px"
      title="驳回原因"
      :append-to-body="true"
      :modal-append-to-body="false"
    >
      <el-form
        ref="form"
        :model="rejectInfo"
        label-width="120px"
      >
        <el-form-item label="驳回人">
          <div>{{ rejectInfo.overruleUserName }}</div>
        </el-form-item>
        <el-form-item label="驳回原因">
          <div>{{ rejectInfo.overruleReason }}</div>
        </el-form-item>
        <el-form-item label="驳回时间">
          <div>{{ rejectInfo.overruleTime }}</div>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible = false">
          取 消
        </el-button>
        <el-button
          type="primary"
          @click="assignSingle"
        >
          指 派
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pagination from '@core/components/pagination/Pagination.vue'
import RiskAssign from '@/views/risk/riskPerception/RiskAssign.vue'
import PageTotalChart from '@/@core/components/page-total-chart/PageTotalChart.vue'
import {
  CloseRisk,
  RiskPage,
  GetEsthesiaRiskCountSatisfaction,
  DeleteRiskManageByIds,
  GetAllRiskTypes,
  GetAllRiskSources,
  GetAllRiskObjects,
} from '@/api/risk/risk'
import {
  error, findBtnPerm, success, getBtnPerms, verifyBtnPerm,
} from '@core/utils/utils'
import FilterSelectList from '@core/components/filter-select-list/FilterSelectList.vue'
import TableTips from '@core/components/table-tips/TableTips'
import TableHeaderFilterDropdown from '@core/components/table-header-fliter-dropdown/TableHeaderFilterDropdown'
import RiskStatistics from '@/views/risk/components/RiskStatistics'
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'

export default {
  components: {
    pagination,
    FilterSelectList,
    TableHeaderFilterDropdown,
    TableTips,
    RiskStatistics,
    PageIntroduce,
    RiskAssign,
    PageTotalChart,
  },
  data() {
    const listQuery = {
      SearchStr: '',
      order: '',
      orderField: '',
      page: 1,
      pageSize: 10,
      riskLevel: 0,
      riskObject: '',
      riskSource: '',
      riskStatus: 0,
      riskType: '',
    }
    return {
      dialogVisible: false,
      rejectInfo: {
        overruleUserName: '',
        riskId: '',
        overruleReason: '',
        overruleTime: '',
      },
      pieLabels1: [],
      pieServise1: [],
      pieLabels2: [],
      pieServise2: [],
      pieLabels3: [],
      pieServise3: [],
      riskTotalInit: false,
      pieChartInit1: false,
      pieChartInit2: false,
      pieChartInit3: false,
      selectList: [],
      levelColorObj: {
        5: 'danger',
        4: 'warning',
        3: 'light-warning',
        2: 'success',
        1: 'success',
      },
      riskStatusObj: {
        1: '待处理',
        2: '驳回 ',
      },
      levelObj: {
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
      riskStatusOptions: [
        { label: '待处理', value: 1 },
        { label: '驳回', value: 2 },
      ],
      levelOptions: [
        { label: '低', value: 2 },
        { label: '中', value: 3 },
        { label: '高', value: 4 },
        { label: '严重', value: 5 },
      ],
      savePerm: findBtnPerm('risk-perception-8'),
      updatePerm: findBtnPerm('risk-perception-3'),
      readPerm: findBtnPerm('risk-perception-2'),
      delPerm: findBtnPerm('risk-perception-4'),
      assginPerm: findBtnPerm('risk-perception-6'),
      closePerm: findBtnPerm('risk-perception-5'),
      loading: false,
      total: 0,
      listQuery: { ...listQuery },
      initListQuery: { ...listQuery },
      tableData: [],
      levels: [],
      products: [],
      sources: [],
      riskSourceOptions: [],
      riskObjectOptions: [],
      riskTypeOptions: [],
      multipleSelection: [],
      barSeriesArr: [],
    }
  },
  created() {
    getBtnPerms(btnPerms => {
      this.savePerm = verifyBtnPerm(btnPerms, 'risk-perception-8')
      this.updatePerm = verifyBtnPerm(btnPerms, 'risk-perception-3')
      this.readPerm = verifyBtnPerm(btnPerms, 'risk-perception-2')
      this.delPerm = verifyBtnPerm(btnPerms, 'risk-perception-4')
      this.assginPerm = verifyBtnPerm(btnPerms, 'risk-perception-6')
      this.closePerm = verifyBtnPerm(btnPerms, 'risk-perception-5')
    })
    this.getAllRiskTypes()
    this.getAllRiskSources()
    this.getAllRiskObjects()
    this.getEsthesiaRiskCountSatisfaction()
    const cacheQuery = this.$store.state.pageQuery.listQuery10
    this.listQuery = { ...cacheQuery }
    this.getList()
  },
  methods: {
    getAllRiskSources() {
      GetAllRiskSources().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskSourceOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    getAllRiskTypes() {
      GetAllRiskTypes().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskTypeOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    getAllRiskObjects() {
      GetAllRiskObjects().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          resData.data.forEach(r => {
            if (r) {
              this.riskObjectOptions.push({
                label: r,
                value: r,
              })
            }
          })
        }
      })
    },
    assignSingle() {
      this.$refs.riskAssign.dialogVisible = true
      this.$refs.riskAssign.form.riskManageIds = []
      this.$refs.riskAssign.form.riskManageIds.push(this.rejectInfo.riskId)
      this.dialogVisible = false
    },
    overruleReasonDialogShow(row) {
      if (row.riskStatus == 2) {
        this.dialogVisible = true
        this.rejectInfo.overruleReason = row.overruleReason
        this.rejectInfo.overruleUserName = row.overruleUserName
        this.rejectInfo.overruleTime = row.overruleTime
        this.rejectInfo.riskId = row.id
      }
    },
    getEsthesiaRiskCountSatisfaction() {
      this.barSeriesArr = []
      GetEsthesiaRiskCountSatisfaction().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.barSeriesArr = [
            resData.data.total,
            resData.data.seriousRiskCount,
            resData.data.heightRiskCount,
            resData.data.middleRiskCount,
            resData.data.lowRiskCount,
          ]
        }
      })
    },
    assignDialogShow() {
      if (this.multipleSelection.length === 0) {
        error('请选择需要指派的风险')
      } else {
        this.$refs.riskAssign.dialogVisible = true
        this.$refs.riskAssign.form.riskManageIds = []
        this.multipleSelection.forEach(m => {
          this.$refs.riskAssign.form.riskManageIds.push(m.id)
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    delRisk(id) {
      this.$confirm('是否确认删除该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        DeleteRiskManageByIds({ ids: [id] }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter(1)
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
    closeRisk(id) {
      this.$confirm('是否确认关闭该风险?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        CloseRisk({ id }).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            success(resData.msg)
            this.handleFilter(1)
          } else {
            error(resData.msg)
          }
        })
      }).catch(() => { })
    },
    riskDetail(id) {
      this.$router.push({
        path: '/risk-perceptionDetail',
        query: {
          id,
        },
      })
    },
    riskSave(type, id) {
      if (type == 2) {
        this.$router.push({
          path: '/risk-save',
          query: {
            id,
          },
        })
      } else {
        this.$router.push({
          path: '/risk-save',
        })
      }
    },
    getList(flag) {
      this.loading = true
      this.listQuery.riskLevel = Number(this.listQuery.riskLevel)
      this.listQuery.riskStatus = Number(this.listQuery.riskStatus)
      RiskPage(1, this.listQuery).then(res => {
        this.loading = false
        this.tableData = res.data.data.list
        this.total = Number(res.data.data.total)
        this.riskTotalInit = true
        if (flag) {
          this.getEsthesiaRiskCountSatisfaction()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    handleFilter(flag) {
      this.listQuery.page = 1
      this.getList(flag)
    },
    sortChange(column) {
      const order = column.order ? column.order : 'ascending'
      const orderObj = { descending: 'DESC', ascending: 'ASC' }
      this.listQuery.orderField = column.column.sortBy
      this.listQuery.order = orderObj[order]
      this.getList()
    },
  },
}
</script>

<style>
.myNote {
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.risk-statistics {
  display: flex;
  justify-content: space-evenly;
}
</style>
